import { ErrorScreen } from "@thepiquelab/archus-components-web";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import {
  OAuthPermission,
  deduplicatedPermissions,
} from "@thepiquelab/web-types";
import { useMemo } from "react";
import InsufficientCredentials from "../../assets/images/InsufficientCredentials.svg";
import { Auth0Config } from "../../config";

interface Props {
  permissions?: OAuthPermission[];
}

const InsufficientCredentialsPage: React.FC<Props> = (props) => {
  const { permissions } = props || {};
  const { logout } = useAuth0Client();

  const description = useMemo(() => {
    const res = deduplicatedPermissions?.filter(
      (i) =>
        i?.permissions?.length &&
        i?.permissions?.every((i: any) => permissions?.includes(i)),
    );

    if (permissions?.length) {
      return `To access this page, you need the ${res
        ?.map((i) => i?.name)
        ?.join(
          ", ",
        )} permission. Please contact your administrator if you believe you should have access.`;
    }
    return "We are sorry, but you do not have access to this page.";
  }, [permissions]);

  return (
    <ErrorScreen
      logo="the-pi-lab"
      title={permissions?.length ? "Access Denied" : "Error"}
      description={description}
      primaryButtonLabel="Return to Login"
      onPressPrimaryButton={() => {
        logout({
          logoutParams: {
            returnTo: Auth0Config.authorizationParams.redirect_uri,
          },
        });
      }}
      illustration={InsufficientCredentials}
    />
  );
};

export default InsufficientCredentialsPage;
