import { AppConfig as ApiConfig } from "@thepiquelab/web-api";

export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE_CORE_SERVICE,
    redirect_uri: window.location.origin ?? "http://localhost:3000",
    scope: process.env.REACT_APP_AUTH0_SCOPES,
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION,
  },
};

export const IsPermissionDebugMode =
  process.env.REACT_APP_PERMISSION_DEBUG_MODE === "true";

export const AppConfig = {
  BackendBaseUrl: ApiConfig.getCoreServiceUrl(),
  UserServiceUrl: ApiConfig.getUserServiceUrl(),
  GraphqlEndpoint: `${ApiConfig.getCoreServiceUrl()}/${
    process.env.REACT_APP_ARCHUS_GRAPHQL_PATH
  }`,
  SubscriptionsEndpoint:
    process.env.REACT_APP_ARCHUS_SUBSCRIPTION_ENDPOINT ||
    "ws://localhost:7001/subscriptions",
  redirectPathname:
    process.env.REACT_APP_REDIRECT_PATH || window.location.pathname,
};

// @ts-ignore
export const IsPoweredByQianKun = window.__POWERED_BY_QIANKUN__;
