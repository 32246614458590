import { ApolloProvider } from "@apollo/client";
import { ConfigProviderToken } from "@thepiquelab/archus-components-web";
import { useAuth0Client } from "@thepiquelab/web-auth0";
import { OAuthPermission } from "@thepiquelab/web-types";
import { lazy, memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import createApolloClient from "./ApolloClient";
import BaseLayout from "./Layout/BaseLayout";
import SpecialLayout from "./Layout/SpecialLayout";
import { IsPoweredByQianKun } from "./config";
import { useAccessControl } from "./hooks/useAccessControl";

const InsufficientCredentialsPage = lazy(
  () => import("./pages/Errors/InsufficientCredentialsPage"),
);

function App() {
  const { getToken } = useAuth0Client();

  const { hasAllPermissions } = useAccessControl();

  if (IsPoweredByQianKun) {
    return (
      <ApolloProvider client={createApolloClient(getToken)}>
        <SpecialLayout />
      </ApolloProvider>
    );
  }

  return (
    <ApolloProvider client={createApolloClient(getToken)}>
      <ConfigProviderToken components={{}}>
        {hasAllPermissions([OAuthPermission.ARCHUS_ADMINISTRATION_ACCESS]) ? (
          <BaseLayout />
        ) : (
          <Routes>
            <Route path="*" element={<Navigate to="/error" />} />
            <Route
              index
              path="/error"
              element={<InsufficientCredentialsPage />}
            />
          </Routes>
        )}
      </ConfigProviderToken>
    </ApolloProvider>
  );
}

export default memo(App);
