import { useAccessControl } from "@thepiquelab/web-auth0";
import { CheckPermissionType, OAuthPermission } from "@thepiquelab/web-types";
import InsufficientCredentialsPage from "../../pages/Errors/InsufficientCredentialsPage";

interface AuthorizedRouteProps {
  element?: React.ReactNode;
  permissions?: OAuthPermission[];
  permissionCheckType?: CheckPermissionType;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = (props) => {
  const { permissions, permissionCheckType, element } = props;
  const { hasAllPermissions, hasSomePermissions } = useAccessControl();

  /**
   * @param permissionsToCheck {OAuthPermission[]} - permissionsToCheck
   * @param type {CheckPermissionType} [type=CheckPermissionType.All] - Indicates whether to permit a user to access if one of the permissions required is in his priviledge or only if all is present.
   * @returns {boolean}
   */
  const hasPermission = (
    permissionsToCheck: OAuthPermission[],
    type: CheckPermissionType = CheckPermissionType.All,
  ): boolean => {
    if (type === CheckPermissionType.Some) {
      return hasSomePermissions(permissionsToCheck);
    }
    return hasAllPermissions(permissionsToCheck);
  };

  if (permissions?.length && !hasPermission(permissions, permissionCheckType)) {
    return <InsufficientCredentialsPage permissions={permissions} />;
  }

  return <>{element}</>;
};

export default AuthorizedRoute;
