import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CacheProvider from "../components/CacheProvider";
import AuthorizedRoute from "../components/authorize/AuthorizedRoute";
import useRouter from "../config/router";

const SpecialLayout: React.FC = () => {
  const { routes, redirectPath } = useRouter();

  return (
    <CacheProvider>
      <Suspense fallback={<Skeleton />}>
        <Routes>
          {routes.map(({ path, element: Element, items }, index) => {
            return items?.length ? (
              <Route path={path} key={`route-${index.toString()}`}>
                {items?.map(
                  (
                    {
                      path: itemPath,
                      element: ItemElement,
                      permissions,
                      permissionCheckType,
                    },
                    itemIndex,
                  ) => (
                    <Route
                      index={!itemIndex}
                      key={`item-route${itemIndex.toString()}`}
                      path={itemIndex ? itemPath : undefined}
                      element={
                        ItemElement ? (
                          <AuthorizedRoute
                            element={<ItemElement />}
                            permissions={permissions}
                            permissionCheckType={permissionCheckType}
                          />
                        ) : null
                      }
                    />
                  ),
                )}
              </Route>
            ) : (
              <Route
                key={`Route${index.toString()}`}
                path={path}
                element={Element ? <Element /> : null}
              />
            );
          })}
          <Route path="*" element={<Navigate to={redirectPath} />} />
        </Routes>
      </Suspense>
    </CacheProvider>
  );
};

export default SpecialLayout;
